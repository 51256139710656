import React from "react";

function ErrorComponent() {
  return (
    <div>
      An Error Occurred. I don't know what to do! Contact support at
      support@javabinks.com
    </div>
  );
}

export default ErrorComponent;
