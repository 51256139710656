import React, { Component } from "react";
import { Link } from "react-router-dom";
import AuthenticationService from "./AuthenticationService.js";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import { fade, makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import SearchIcon from "@material-ui/icons/Search";
import MoreIcon from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";

import { CartIconComponent } from "../cart/CartIconComponent.jsx";

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  logo: {
    color: "white"
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto"
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200
    }
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex"
    }
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  }
}));

function PrimarySearchAppBar(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  function handleProfileMenuOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleMenuClose() {
    setAnchorEl(null);
    handleMobileMenuClose();
  }

  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <Button color="inherit">
          {props.isUserLoggedIn && (
            <Link className="nav-link" to="/cart">
              <CartIconComponent cartTotal={props.cart.length} />
            </Link>
          )}

          {props.isUserLoggedIn ? (
            <li>
              <Link className="nav-link" to="/list-product">
                List Product
              </Link>
            </li>
          ) : (
            ""
          )}
          {props.isUserLoggedIn && (
            <li>
              <Link className="nav-link" to="/map">
                Map
              </Link>
            </li>
          )}
          {props.isUserLoggedIn ? (
            <li>
              <Link className="nav-link" to="/past-orders">
                Past Orders
              </Link>
            </li>
          ) : (
            ""
          )}
          {!props.isUserLoggedIn ? (
            <li>
              <React.Fragment>
                <Link className="nav-link" to="/login">
                  Login
                </Link>
                <Link className="" to="/sign-up">
                  Sign Up
                </Link>
              </React.Fragment>
            </li>
          ) : (
            ""
          )}
          {props.isUserLoggedIn ? (
            <li>
              <Link
                className="nav-link"
                to="/logout"
                onClick={AuthenticationService.logout}
              >
                Logout
              </Link>
            </li>
          ) : (
            ""
          )}
        </Button>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="static">
        <Toolbar>
          {/*<IconButton*/}
          {/*  edge="start"*/}
          {/*  className={classes.menuButton}*/}
          {/*  color="inherit"*/}
          {/*  aria-label="open drawer"*/}
          {/*>*/}
          {/*  <MenuIcon />*/}
          {/*</IconButton>*/}
          <Link className={classes.logo} to="/" color="secondary">
            <Typography className={classes.title} variant="h6" noWrap>
              Java Binks
            </Typography>
          </Link>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              inputProps={{ "aria-label": "search" }}
            />
          </div>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Button color="inherit">
              {props.isUserLoggedIn && (
                <Link className="nav-link" to="/cart">
                  <CartIconComponent cartTotal={props.cart.length} />
                </Link>
              )}
            </Button>

            <Button color="inherit">
              {props.isUserLoggedIn ? (
                <Link className="nav-link" to="/list-product">
                  List Product
                </Link>
              ) : (
                ""
              )}
            </Button>

            <Button color="inherit">
              {props.isUserLoggedIn && (
                <Link className="nav-link" to="/map">
                  Map
                </Link>
              )}
            </Button>
            <Button color="inherit">
              {props.isUserLoggedIn && (
                <Link className="nav-link" to="/past-orders">
                  Past Orders
                </Link>
              )}
            </Button>
            <Button color="inherit">
              {!props.isUserLoggedIn ? (
                <React.Fragment>
                  <Link className="nav-link" to="/login">
                    Login
                  </Link>
                  <Link className="" to="/sign-up">
                    Sign Up
                  </Link>
                </React.Fragment>
              ) : (
                ""
              )}
              {props.isUserLoggedIn ? (
                <Link
                  className="nav-link"
                  to="/logout"
                  onClick={AuthenticationService.logout}
                >
                  Logout
                </Link>
              ) : (
                ""
              )}
            </Button>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}

export default class HeaderComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <header>
          <PrimarySearchAppBar {...this.props} />
        </header>
      </React.Fragment>
    );
  }
}
